/*
 * @Author: 自由命名
 * @Date: 2023-11-30 19:22:11
 * @LastEditors: 自由命名
 * @LastEditTime: 2024-01-31 18:02:24
 * @FilePath: \talent-front-end\src\utils\config.js
 * @filePurpose:
 */
export default {

  // baseURL: 'http://localhost:18686'
  // baseURL:'http://54.223.125.162:80  51', //正式环境ip/
   baseURL: 'https://www.oilgasjobai.com',
  // baseURL: `${location.origin}`
}
