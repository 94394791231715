<template>

  <div>

    <div >
      <div v-if="home===true||home==='true'" class="footer">
        <div style="width: 20%;" @click="homeback()">
           <div class="communityStyle">
             <img src="@/assets/homeLogo.png">
           </div>
        <div style="color: var(--main-color)">
          首页
        </div>
        </div>
        <div style="width: 20%;">
          <a href="https://www.oilgascommunity.com/">
            <div class="communityStyle">
              <img src="@/assets/community.png">
            </div>
            <div>
              社区
            </div>

          </a>
        </div>
        <div style="width: 20%;">
          <a href="https://cn.oilgasmall.com/">
            <div class="communityStyle">
              <img src="@/assets/exhind.png">
            </div>
            <div>
              展馆
            </div>
          </a>
        </div>
        <div style="width: 20%;" @click="tackConstruct()">
          <a href="https://www.oilgasinfoai.com/">
            <div class="communityStyle">
              <img src="@/assets/recruite.png">
            </div>
            <div>
              资讯
            </div>
          </a>
        </div>
        <div style="width: 20%;" @click="tack()">
          <div class="communityStyle">
            <img src="@/assets/myDispear.png">
          </div>
          <div>
            我的
          </div>
        </div>

      </div>
      <div v-else class="footer">
        <div style="width: 20%;" @click="homeback()">
           <div class="communityStyle">
             <img src="@/assets/homeDispeare.png">
           </div>
           <div>
             首页
           </div>
        </div>
        <div style="width: 20%;">
          <a href="https://www.oilgascommunity.com/">
            <div class="communityStyle">
              <img src="@/assets/community.png">
            </div>
            <div>
              社区
            </div>

          </a>
        </div>
        <div style="width: 20%;">
          <a href="https://cn.oilgasmall.com/">
            <div class="communityStyle">
              <img src="@/assets/exhind.png">
            </div>
            <div>
              展馆
            </div>
          </a>
        </div>
        <div style="width: 20%;" @click="tackConstruct()">
          <a href="https://www.oilgasinfoai.com/">
            <div class="communityStyle">
              <img src="@/assets/recruite.png">
            </div>
            <div>
              资讯
            </div>
          </a>
        </div>
        <div style="width: 20%;color: var(--main-color)" @click="tack()">
          <div class="communityStyle">
            <img src="@/assets/mySelect.png">
          </div>
          <div>
            我的
          </div>
        </div>
      </div>

    </div>

    <PClogin
        :dialogVisible="cancleVisible"
        @hide="hide"
    ></PClogin>
    <construct
        :dialogVisible="constructVisible"
        @hide="hideConstruct"
    ></construct>
<!--    <CancleTip-->
<!--        :dialogVisible="cancleVisible"-->
<!--        @hide="hide"-->
<!--    ></CancleTip>-->
  </div>

</template>


<script>

import PClogin from "@/components/PClogin.vue";
import construct from "@/components/construct.vue";
// import Information from "@/views/Information.vue";

export default {

  name: "BottomBanner",

  props: [],

  components: {
    // Information,
    PClogin,construct},

  data() {

    return {
      home:sessionStorage.getItem("home")||true,
      languageSelect:'',
      cancleVisible: false,
      constructVisible:false
    }

  },
created() {
 console.log("home",this.home===true)
},
  methods: {
    hide() {
      this.cancleVisible = false;
      document.title="油气人才"
    },
    hideConstruct(){
      this.constructVisible=false
      document.title="油气人才"
    },
    tack(){
      sessionStorage.setItem("home",false)
      this.$router.push("/user")
      this.home=false
      document.title="我的信息"
    },
    homeback(){
      sessionStorage.setItem("home",true)
      this.$router.push("/")
      this.home=true
      document.title="我的信息"
    },
    tackConstruct(){
      document.title="油气通"
    }
  },

  mounted() {


  },
  watch: {
    // 监听 myValue 的变化
    home(newValue, oldValue) {
      console.log(`myValue changed from ${oldValue} to ${newValue}`);
      // 在这里可以执行其他逻辑
    },
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.homeStyle{
  width: 20px;
  height: 25px;
  margin: 0 auto;
  background-image: url("../assets/home.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}
.communityStyle{
  width: 20px;
  height: 20px;
  margin: 0 auto 3px auto;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}

.footer {
  //border-top: 1px solid gray;
  //background-color: #6a96f8;
  background:linear-gradient(
          to right, #bad3fa 0%, #b2f1d2 50%,rgb(253, 202, 202) 100%);
  height: 55px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  //background-color: #ffffff;
  color: black;
  font-size: 14px;
  text-align: center;
  padding:8px 10px;
  font-weight: 500;
  z-index: 9999; /* 设置一个较大的 z-index 值 */
}

.nav-link {
  color: #fff;
  text-decoration: none;
  margin: 0 15px;
}
</style>
