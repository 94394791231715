<template>
  <div class="MySubscriptions wrapper" style="overflow-x: hidden">

    <div v-if="isMobile === false">
      <keep-alive>
        <Header></Header>
      </keep-alive>
      <div class="centerWidth mainContent">
        <div class="hotBox">
          <div class="banner">
            <img loading="lazy" src="../assets/top.png"/>
          </div>

<!--          <div class="recommend1" v-if="userId">-->
<!--            <PersonalLittle></PersonalLittle>-->
<!--          </div>-->
<!--          <div class="recommend2" v-else @click="showLoginIn()">-->
<!--            <loginDemo-->
<!--                @register="showRegister"-->
<!--                @forget="forget"-->
<!--            ></loginDemo>-->
<!--          </div>-->

        </div>
        <div class="bannerBottom">
          <div class="flexBox">
            <div class="searchBox" @click="change">
<!--              <el-dropdown>-->
<!--              <span class="el-dropdown-link">-->
<!--                职位<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--              </span>-->
<!--              </el-dropdown>-->
<!--              <span class="spanStyle">-->
<!--              </span>-->
              <el-input
                  class="searchContent"
                  v-model="seachInput"
                  placeholder="请输入搜索关键词"
              ></el-input>
              <el-button class="searchTo"   @click="search" >搜索
              </el-button>
            </div>


            <div class="scanEr">
              <Erweima></Erweima>
            </div>

          </div>
        </div>



        <!--          热招岗位-->
        <hotPosition :key="keywords"></hotPosition>

        <!--          推荐岗位-->
        <commentPosition :key="keywords"></commentPosition>

      </div>
    </div>
    <div v-else class="mobile">
      <div class="centerWidth mainContent">
        <div class="hotBox1">
          <div class="banner">
            <img loading="lazy" src="../assets/top1.png"/>
          </div>
        </div>
        <keep-alive>
          <Header></Header>
        </keep-alive>
        <div class="bannerBottom">
          <div class="flexBox1">
            <div class="searchBox" @click="change">
<!--              <el-dropdown>-->
<!--              <span class="el-dropdown-link">-->
<!--                职位<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--              </span>-->
<!--              </el-dropdown>-->
              <span class="spanStyle">
              </span>
              <el-input
                  class="searchContent"
                  v-model="seachInput"
                  placeholder="请输入职位关键词"
              ></el-input>
              <el-button class="searchTo"   @click="search" >搜索
              </el-button>
            </div>

          </div>
          <div class="personal">
            <div class="my" @click="myRecruit">
              <div class="myphoto">
                <img src="../assets/u12.png">
              </div>
              我的简历
            </div>
            <div class="my" @click="pass">
              <div class="myphoto">
                <img src="../assets/u9.png">
              </div>
              我的投递
            </div>
            <div class="my" @click="coll">
              <div class="myphoto">
                <img src="../assets/u6.png">
              </div>
              我的收藏
            </div>
            <div class="my" @click="scan">
              <div class="myphoto">
                <img src="../assets/u15.png">
              </div>
              浏览记录
            </div>
          </div>
        </div>


        <!--          热招岗位-->
        <hotPosition :key="seachInput"></hotPosition>

        <!--          推荐岗位-->
        <commentPosition :key="seachInput"></commentPosition>

        <bottomMobile></bottomMobile>
      </div>
    </div>
    <Footer v-if="isMobile === false"></Footer>
    <BottomBanner v-if="isMobile === true"></BottomBanner>

    <LoginTip
        :dialogVisibleLogin="dialogVisibleLogin"
        @hideDialog="hideDialogin"
    ></LoginTip>
    <InfoTip
      :dialogVisibleInfo="dialogVisibleInfo"
      @hideDialog="hideDialogInfo"
    ></InfoTip>
    <LoginIn
        :dialogVisible="loginIn"
        @hideDialog="hideLoginIn"
        @register="showRegister"
        @forget="forget"
        @logsuccess="logsuccess"
    ></LoginIn>
    <RegisterIn
        :dialogVisible="registerIn"
        @hideDialog="hideregisterIn"
        @login="login"
    ></RegisterIn>
    <forgetIn
        :dialogVisible="forgetIn"
        @hideDialog="hidereforgetIn"
        @login="login"
    ></forgetIn>

  </div>
</template>
<script>
import {
  // recommend,
  categoryRecommend,
  businessList,
  reportList,

} from "@/api/index";
import RegisterIn from "@/views/userlog/RegisterIn.vue";
import Erweima from "@/views/candidate/Erweima.vue";
import bottomMobile from "@/components/bottomMobile.vue";
// import loginDemo from "@/views/userlog/loginDemo.vue";
import hotPosition from "@/views/candidate/hotPosition.vue";
import commentPosition from "@/views/candidate/commentPosition.vue";
import "swiper/dist/css/swiper.css";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import InfoTip from "@/components/InfoTip.vue";
import BottomBanner from "@/components/BottomBanner.vue";
import { shareUrl } from "@/utils/vxshare.js";
// import PersonalLittle from "@/components/PersonalLittle.vue";
import LoginIn from "@/views/userlog/LoginIn.vue";
import forgetIn from "@/views/userlog/forgetIn.vue"
import LoginTip from "@/components/LoginTip.vue";
export default {
  name: "Home",
  components: {
    LoginTip,
    forgetIn,
      Erweima,
    bottomMobile,
    BottomBanner,
    commentPosition,
    Header,
    hotPosition,
    Footer,
    LoginIn,
    RegisterIn,
    InfoTip,
  },
  data() {
    return {
      keywords:'',
      loginIn:false,
      registerIn:false,
      forgetIn:false,
      seachInput:'',
      isMobile: false,
      languageSelect: "",
      baseURL: this.$utils.baseURL,
      token:localStorage.getItem("access_token"),
      swiperList: [
        {
          img: require("@/assets/banner.png"),
          title: "中国石化发布“一带一路”十年履责报告",
        },
        {
          img: require("../assets/banner.png"),
          title: "中国石化发布“一带一路”十年履责报告",
        },
        {
          img: require("../assets/banner.png"),
          title: "中国石化发布“一带一路”十年履责报告",
        },
        {
          img: require("../assets/banner.png"),
          title: "中国石化发布“一带一路”十年履责报告",
        },
        {
          img: require("../assets/banner.png"),
          title: "中国石化发布“一带一路”十年履责报告",
        },
        {
          img: require("../assets/banner.png"),
          title: "中国石化发布“一带一路”十年履责报告",
        },
        {
          img: require("../assets/banner.png"),
          title: "中国石化发布“一带一路”十年履责报告",
        },
      ],
      swiperOption: {
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        loop: true,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
          // dynamicBullets: true,
          // dynamicMainBullets: 4,
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      recommendList: [],
      categoryRecommendList: [],
      tableList: {
        title: "商机洞察",
        titleEn: "Business",
        url: "/Science?id=3",
        list: [],
        status: null,
        isWhole: null,
      },
      pdfList: {
        title: "行业报告",
        titleEn: "Reports",
        url: "/Science?id=2",
        list: [],
      },
      dialogVisible: false,
      dialogVisibleLogin:false,
      dialogVisibleInfo: false,
      titleEn: "",
      userId: localStorage.getItem("userId"),
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    this.userId= localStorage.getItem("userId")
    console.log("-------------")
    console.log(this.userId)
    this.recommend();
    this.categoryRecommend();
    this.businessList();
    this.reportList();
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url:url,
        title:'油气人才',
        text:'全球油气行业专业人才求职招募平台，AI辅助简历优化，在线雇主及时沟通，更多油气行业岗位，快来油气人才吧！',
      }
      shareUrl(shareData);
    }, 1000);
  },
  methods: {
    hideLoginIn(){
      this.loginIn=false

    },
    logsuccess(){
      this.loginIn=false
      this.userId= this.$store.state.userId
      window.location.reload();

    },
    showRegister(){
      this.loginIn=false
      this.registerIn=true
    },
    forget(){
      this.loginIn=false
      this.forgetIn=true
    },
    hideregisterIn(){
      this.registerIn=false
    },
    hidereforgetIn(){
      this.forgetIn=false
    },
    login(){
      this.loginIn=true
      this.registerIn=false
    },
    showLoginIn(){
      this.loginIn=true
    },
    change(){
      this.$router.push("/recruit")
    },
    search(){
      this.keywords=this.seachInput
    },
    recommend() {
      this.$axios.get("/system/job_space/list" , {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
      })
          .then((res) => {
            this.recommendList = res.data.rows;
          }).catch(error=>{
        console.log(error)
      })
    },
    categoryRecommend() {
      categoryRecommend().then((res) => {
        this.categoryRecommendList = res.data;
      });
    },
    businessList() {
      businessList().then((res) => {
        console.log(res);
        this.tableList.list = res.data.data;
        this.tableList.isWhole = res.data.isWhole;
        this.tableList.status = res.data.status;
        businessList().then(async (res) => {
          this.tableList = res.data.data;
          // 遍历tableList中的每个项目
          for (const item of this.tableList) {
            var vm = this;
            await vm.$axios
              .post("/systemenes/trans", {
                newsId: item.id,
                headers: {
                  Authorization: vm.token,
                },
              })
              .then((res) => {
                vm.titleEn = res.data.data.enTitle;
                vm.contentEn = res.data.data.enContent;
                this.$set(this, "titleEn", vm.titleEn);
              })
              .catch((error) => {
                console.log(error);
              });
            item.titleEn = this.titleEn;
            // 假设setText和内容翻译函数在其他地方定义
            if (item.content) item.contentText = this.setText(item.content);
            if (this.languageSelect === "en") {
              item.title = this.setText(item.titleEn);
              if (item.secondaryName === "中文商机") {
                item.secondaryName = this.setText("Chinese Business");
              }
              if (item.secondaryName === "英文商机") {
                item.secondaryName = this.setText("English Business");
              }
            }
          }
        });
      });
    },

    reportList() {
      reportList().then((res) => {
        this.pdfList.list = res.rows;
      });
    },
    toDetail(item) {
      // eslint-disable-next-line no-empty
      if (!this.$store.state.access_token && item.isView != "1") {
        this.showDialog(true);
        return false;
      }
      if (this.$store.state.typeInfo == 0 && item.isWhole != "1") {
        let that = this;
        that.showDialogInfo(true);
        return false;
      }
      // this.$router.push(item.url);
      window.location.href = item.url;
    },
    showDialog(type) {
      this.dialogVisible = type;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showDialogInfo(type) {
      this.dialogVisibleInfo = type;
    },
    hideDialogInfo() {
      this.dialogVisibleInfo = false;
    },
    hideDialogin() {
      this.dialogVisibleLogin = false;
    },
    myRecruit(){
      if(this.userId===null||this.userId===undefined||this.userId===""){
        console.log("555")
        this.dialogVisibleLogin=true
      }
      else{
        this.$router.push("/Info1")
      }

    },
    pass(){
      if(this.userId===null||this.userId===undefined||this.userId===""){
        console.log("555")
        this.dialogVisibleLogin=true
      }
      else{
        this.$router.push("/jobCommit1")
      }

    },
    coll(){
      if(this.userId===null||this.userId===undefined||this.userId===""){
        console.log("555")
        this.dialogVisibleLogin=true
      }
      else{
        this.$router.push("/jobCollect1")
      }

    },
    scan(){
      if(this.userId===null||this.userId===undefined||this.userId===""){
        console.log("555")
        this.dialogVisibleLogin=true
      }
      else{
        this.$router.push("/jobCommunicate1")

      }


    },
  },
  mounted() {
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;

    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    console.log(screenWidth)

  },
};
</script>
<style lang="scss" scoped>

.centerWidth{
  overflow-x: hidden;
  width: 100vw;
}
.hotBox {
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  .banner {
    width: 100%;
    height: 100%;
    overflow: hidden;
    img{
      background-size:contain;
      background-position:center;
      background-repeat:no-repeat
    }
  }
  .recommend1 {
    left:68vw;
    position: absolute;
    width: 280px;
    height: 340px;
    top:66px;
    border-radius: 4px 4px 4px 4px;
    .item {
      width: 100%;
      cursor: pointer;
      padding: 2.3vh 1.0vw;
      font-size: 18px;
      color: #333333;
    }
    .item:hover {
      background: var(--main-color);
      color: #fff;
    }
    .item:not(:last-child) {
      border-bottom: 1px dotted #cecece;
    }
  }
  .recommend2 {
    left: 68vw;
    position: absolute;
    width: 280px;
    height: 340px;
    top:30px;
    box-shadow: 0px 0px 1.4vh 0px rgba(0,0,0,0.05);
    border-radius: 4px 4px 4px 4px;
    .item {
      width: 100%;
      cursor: pointer;
      padding: 2.3vh 1.0vw;
      font-size: 18px;
      color: #333333;
    }
    .item:hover {
      background: var(--main-color);
      color: #fff;
    }
    .item:not(:last-child) {
      border-bottom: 1px dotted #cecece;
    }
  }
}
.bannerBottom{
  background-color: white;
  height: 120px;
  width: 100%;
}
.flexBox {
  background-color: white;
  display: flex;
  border-radius: 4px;
  margin:0 auto;
  padding-top:3.2vh ;
  width:1200px;
  position: relative;
  font-size: 16px;
  height: 56px;
  .spanStyle{
    width: 1px;
    font-size: 16px;
    height: 1.48vh;
    background: #D9D9D9;
  }
  .el-dropdown{
    width:5.63vw;
    margin-left: 15px;
  }
  .el-dropdown-link {
    margin-left: 0.7vw;
    cursor: pointer;
    color:#333333;
  }
  .el-icon-arrow-down {
    font-size: 16px;
  }
  .searchBox {
    border-radius: 53px;
    width: 75%;
    height: 50px;
    margin: auto 0;
    border: rgba(191, 0, 34, 0.5) 2px solid; ;
    //box-shadow: 0px 0px 16px 0px rgba(191, 0, 34, 0.1);
    background-color:#FFFFFF ;
    display: flex;
    align-items: center;
    justify-content: center;
    //border: 1px solid var(--main-color);
    ::v-deep .el-button{
      padding:0.8vh 0.7vw !important;
    }


    .searchContent {

      border-radius: 53px;
      height: 45px;

      ::v-deep .el-input__inner {
        height: 100%;
      }

      ::v-deep .el-input__inner::placeholder {
        color: #999;
      }

    }
    ::v-deep .el-color-picker__icon, .el-input, .el-textarea {
      display: inline-block;
      width: 99%;
      margin-left: 15px;
    }

    .searchTo {
      width:100px;
      height: 38px;
      color: white;
      font-size:14px;
      border-radius: 53px;
      background: #BF0022;
      margin-right: 6px;

      img {
        width: 1.1vw;
        height: 1.9vh;
      }
    }
  }


}

.scanEr{
  position:absolute;
  right: 0;
  top:1vh;
  display: flex;
  background: #FEFFFF;


}

.marginTop {
  margin-top: 30px;
}


.mobile{
  //background-color: #608ff6;
  .hotBox1 {
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    .banner {
      margin-top: 50px;
      width: 100%;
      height:150px;
      overflow: hidden;
      img{
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat
      }
    }
    .recommend1 {
      left:68vw;
      position: absolute;
      width: 280px;
      height: 340px;
      top:66px;
      border-radius: 4px 4px 4px 4px;
      .item {
        width: 100%;
        cursor: pointer;
        padding: 2.3vh 1.0vw;
        font-size: 18px;
        color: #333333;
      }
      .item:hover {
        background: var(--main-color);
        color: #fff;
      }
      .item:not(:last-child) {
        border-bottom: 1px dotted #cecece;
      }
    }
    .recommend2 {
      left: 68vw;
      position: absolute;
      width: 280px;
      height: 340px;
      top:30px;
      box-shadow: 0px 0px 1.4vh 0px rgba(0,0,0,0.05);
      border-radius: 4px 4px 4px 4px;
      .item {
        width: 100%;
        cursor: pointer;
        padding: 2.3vh 1.0vw;
        font-size: 18px;
        color: #333333;
      }
      .item:hover {
        background: var(--main-color);
        color: #fff;
      }
      .item:not(:last-child) {
        border-bottom: 1px dotted #cecece;
      }
    }
  }
  .bannerBottom{
    height: fit-content;
  }
  .flexBox1 {
    display: flex;
    border-radius: 53px;
    margin:0 auto;
    padding-top:3.2vh ;
    width:100vw;
    position: absolute;
    top:160px;
    font-size: 16px;
    height: 56px;
    .spanStyle{
      width: 1px;
      font-size: 14px;
      height: 25px;
      background: #D9D9D9;
    }
    .el-dropdown{
      width:20%;
    }
    .el-dropdown-link {
      margin-left: 0.7vw;
      cursor: pointer;
      color:#333333;
    }
    .el-icon-arrow-down {
      font-size: 16px;
    }
    .searchBox {
      border-radius: 53px;
      width:100%;
      height: 35px;
      margin: auto 35px;
      border: rgb(89, 142, 246) 2px solid; ;
      //box-shadow: 0px 0px 16px 0px rgba(191, 0, 34, 0.1);
      background-color:#FFFFFF ;
      display: flex;
      align-items: center;
      justify-content: center;
      //border: 1px solid var(--main-color);
      ::v-deep .el-button{
        padding:0.8vh 0.7vw !important;
      }


      .searchContent {

        height: 32px;

        ::v-deep .el-input__inner {
          height: 100%;
          border-radius: 53px;
        }

        ::v-deep .el-input__inner::placeholder {
          color: #999;
        }

      }
      ::v-deep .el-color-picker__icon, .el-input, .el-textarea {
        display: inline-block;
        width: 99%;
      }

      .searchTo {
        width:86px;
        height:32px;
        color: #5a8ff6;
        font-size:14px;
        border: none;
        //background: #BF0022;
        border-radius:53px;
        margin-right: 0;

        img {
          width: 1.1vw;
          height: 1.9vh;
        }
      }
    }

  }
  .personal{
    padding: 40px 10px 20px 10px;
    height: fit-content;
    background-color: white;
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    .my{
      font-size: 14px;
      width: 100%;
      text-align: center;
      .myphoto{
       margin: 0 auto;
        width: 80px;
        height: 80px;
      }

    }
  }
  .marginTop1 {
    margin-top: 10px;
  }
}
</style>
<style>
.swiper-container .swiper-pagination-bullet {
  background: #c2c2c2 !important;
  opacity: 1;
  width: 6px;
  height: 6px;
  border-radius: 3px;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background: rgba(0, 0, 0, 0.25) url(../assets/left.png) no-repeat center
    center;
  width: 26px;
  height: 38px;
  border-radius: 0px 5px 5px 0px;
  left: 0;
  background-size: 9px auto;
}
/* .swiper-button-prev:hover, .swiper-container-rtl .swiper-button-next:hover {
  background-image: url(../assets/leftHover.png);;
} */
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background: rgba(0, 0, 0, 0.25) url(../assets/right.png) no-repeat center
    center;
  width: 26px;
  height: 38px;
  right: 0;
  border-radius: 5px 0px 0px 5px;
  background-size: 9px auto;
}
/* .swiper-button-next:hover, .swiper-container-rtl .swiper-button-prev:hover {
  background-image: url(../assets/rightHover.png);
} */
.swiper-button-prev:hover,
.swiper-container-rtl .swiper-button-next:hover,
.swiper-button-next:hover,
.swiper-container-rtl .swiper-button-prev:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.swiper-container .swiper-pagination-bullet-active {
  width: 18px;
  height: 6px;
  border-radius: 3px;
  background: var(--main-color) !important;
}
</style>

