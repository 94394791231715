<template>

  <div class="right">
    <div class="tabsBar">
      <div
          class="barItem"
          @click="barChange()"
          :class="isbarActive == 1 ? 'activebarCls' : ''"
      >
        求职者
      </div>
      <div
          class="barItem"
          @click="barChange()"
          :class="isbarActive == 2 ? 'activebarCls' : ''"
      >招聘者
      </div>
    </div>
    <div v-if="isbarActive == 1">
      <div class="tabs">
        <div
            class="tabsItem"
            @click="tabsChange()"
            :class="isActive == 1 ? 'activeCls' : ''"
        >
          手机号注册
        </div>
        <div
            class="tabsItem"
            @click="tabsChange()"
            :class="isActive == 2 ? 'activeCls' : ''"
        >
          邮箱注册
        </div>
      </div>
      <div class="tabContain">
        <div class="items" :class="isActive == 1 ? 'activeCls' : ''">
          <el-input
              placeholder="请输入昵称或姓名，最多可输入20字"
              maxlength="20"
              v-model="phoneform.name"
          ></el-input>
          <el-input
              placeholder="请输入手机号"
              v-model="phoneform.phone"
              class="input-with-select"
          >
            <el-select
                v-model="phoneform.areaCode"
                slot="prepend"
                placeholder="请选择"
            >
              <el-option
                  v-for="(item, index) in cityCode"
                  :key="index"
                  :label="`${item.cn} (+${item.code})`"
                  :value="item.code"
              >
              </el-option>
            </el-select>
          </el-input>
          <div class="flex">
            <el-input
                placeholder="请输入验证码"
                v-model="phoneform.code"
            ></el-input>
            <el-button
                type="text"
                :disabled="disabled"
                class="setCode"
                @click="getCode()"
            >{{ setCode }}</el-button
            >
          </div>
          <el-input
              placeholder="请输入密码"
              v-model="phoneform.password"
              show-password
              @input="changePasToop1()"
          ></el-input>
          <p class="pwsRed">{{ toop1 }}</p>
          <el-input
              placeholder="请再次输入您的密码"
              v-model="phoneform.requirePas"
              show-password
          ></el-input>
          <div class="checkbox">
            <el-checkbox v-model="checked1">我已阅读并接受</el-checkbox>
            <span class="red" @click="yinsi()">《隐私协议》</span>和<span
              class="red"
              @click="zhuce()"
          >《注册协议》</span
          >
          </div>
        </div>
        <div class="items" :class="isActive == 2 ? 'activeCls' : ''">
          <el-input
              placeholder="请输入昵称或姓名，最多可输入20字"
              maxlength="20"
              v-model="emailform.name"
          ></el-input>
          <el-input
              placeholder="请输入邮箱"
              v-model="emailform.email"
          ></el-input>
          <div class="flex">
            <el-input
                placeholder="请输入验证码"
                v-model="emailform.code"
            ></el-input>
            <el-button
                type="text"
                :disabled="disabled1"
                class="setCode"
                @click="getCode()"
            >{{ setCode1 }}</el-button
            >
          </div>
          <el-input
              placeholder="请输入密码"
              v-model="emailform.password"
              show-password
              @input="changePasToop2()"
          ></el-input>
          <p class="pwsRed">{{ toop2 }}</p>
          <el-input
              placeholder="确认密码"
              v-model="emailform.requirePas"
              show-password
          ></el-input>
          <div class="checkbox">
            <el-checkbox v-model="checked2">我已阅读并接受</el-checkbox>
            <span class="red" @click="yinsi()">《隐私协议》</span>和<span
              class="red"
              @click="zhuce()"
          >《注册协议》</span
          >
          </div>
        </div>
        <div class="submit" @click="submit()">注册</div>
        <div class="flex">
          <div class="login" @click="login()">
            已有账号？<span>立即登录</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isbarActive == 2">
      <img loading="lazy" src="@/assets/jianshe.png">
      <div style="text-align: center">正在建设，敬请期待</div>
    </div>
  </div>

</template>


<script>

import city from "@/utils/city";
import {agreement, emailCode, emailRegister, phoneCode, phoneRegister} from "@/api/user";

export default {
  name: "Register",
  data() {
    return {
      isbarActive:1,
      isMobile:false,
      languageSelect:'',
      checked1: false,
      checked2: false,
      isActive: 1,
      disabled: false,
      disabled1: false,
      cityCode: city.CityOverseasgx,
      emailform: {
        email: "",
        code: "",
        password: "",
        requirePas: "",
      },
      phoneform: {
        name: "",
        areaCode: "",
        phone: "",
        code: "",
        password: "",
        requirePas: "",
      },
      setCode: "发送验证码",
      setCode1: "发送验证码",
      setCodeEn: "Verification code",
      setCode1En: "Verification code",
      yinsiDialog: false,
      zhuceDialog: false,
      yinsiContent: "",
      zhuceContent: "",
      toop1: "",
      toop2: "",
    };
  },
  computed: {},
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }
    this.getYinsi();
    this.getZhuce();

  },
  methods: {
    login(){
      this.$emit('login')
    },
    barChange(){
      this.isbarActive=this.isbarActive==1?2:1
    },
    getYinsi() {

      if(this.languageSelect==="en"){
        agreement(4).then((res) => {
          this.yinsiContent = res.data.content;
        });
      }
      else{
        agreement(1).then((res) => {
          this.yinsiContent = res.data.content;
        });
      }
    },
    getZhuce() {

      if(this.languageSelect==="en"){
        agreement(5).then((res) => {
          this.zhuceContent = res.data.content;
        });
      }
      else{
        agreement(2).then((res) => {
          this.zhuceContent = res.data.content;
        });
      }
    },
    yinsi() {
      this.yinsiDialog = true;
    },
    agreeYinsi() {
      this.yinsiDialog = false;
      if (this.isActive == 1) {
        this.checked1 = true;
      } else {
        this.checked2 = true;
      }
    },
    agreeZhuce() {
      this.zhuceDialog = false;
      if (this.isActive == 1) {
        this.checked1 = true;
      } else {
        this.checked2 = true;
      }
    },
    zhuce() {
      this.zhuceDialog = true;
    },
    tabsChange() {
      this.isActive = this.isActive == 1 ? 2 : 1;
    },
    //获取验证码 并只验证手机号 是否正确
    getCode() {
      if (this.isActive == 1) {
        const reg = /^\d{6,13}$/;
        if (!this.phoneform.areaCode) {
          this.$message.error("请选择区号");
          return false;
        }
        if (!this.phoneform.phone) {
          this.$message.error("请输入手机号");
          return false;
        }
        if (!reg.test(this.phoneform.phone)) {
          this.$message.error("请输入正确的手机号");
          return false;
        }
        let data = {
          phone: this.phoneform.phone,
          areaCode: this.phoneform.areaCode,
          event: "register",
        };
        phoneCode(data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.tackBtn(); //验证码倒数60秒
        });
      } else {
        if (!this.emailform.email) {
          this.$message.error("请输入邮箱");
          return false;
        }
        let data = {
          email:this.emailform.email,
          event: "register",
        }
        emailCode(data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.tackBtn1(); //验证码倒数60秒
        });
      }
    },
    getCodeEn() {
      if (this.isActive == 1) {
        const reg = /^\d{6,13}$/;
        if (!this.phoneform.areaCode) {
          this.$message.error("Please select an area code");
          return false;
        }
        if (!this.phoneform.phone) {
          this.$message.error("Please enter your phone number");
          return false;
        }
        if (!reg.test(this.phoneform.phone)) {
          this.$message.error("Please enter the correct phone number");
          return false;
        }
        let data = {
          phone: this.phoneform.phone,
          areaCode: this.phoneform.areaCode,
          event: "register",
        };
        phoneCode(data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.tackBtnEn(); //验证码倒数60秒
        });
      } else {
        if (!this.emailform.email) {
          this.$message.error("Please enter email");
          return false;
        }
        let data = {
          email:this.emailform.email,
          event: "register",
        }
        emailCode(data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.tackBtn1En(); //验证码倒数60秒
        });
      }
    },
    submit() {
      if (this.isActive == 1) {
        if (!this.phoneform.name) {
          this.$message.error("请输入昵称");
          return false;
        }
        if (!this.phoneform.areaCode) {
          this.$message.error("请选择区号");
          return false;
        }
        if (!this.phoneform.phone) {
          this.$message.error("请输入手机号");
          return false;
        }
        if (!this.phoneform.code) {
          this.$message.error("请输入验证码");
          return false;
        }
        if (!this.phoneform.password) {
          this.$message.error("请输入密码");
          return false;
        }
        if (
            this.phoneform.password.length < 6 ||
            this.phoneform.password.length > 20
        ) {
          this.$message.error("请输入6-20位密码");
          return false;
        }
        if (!this.phoneform.requirePas) {
          this.$message.error("请再次输入密码");
          return false;
        }
        if (this.phoneform.password !== this.phoneform.requirePas) {
          this.$message.error("两次密码不一致");
          return false;
        }
        if (this.checked1 == false) {
          this.$message.error("请阅读并同意隐私协议和注册协议");
          return false;
        }
        let data = {
          code: this.phoneform.code,
          password: this.phoneform.password,
          phone: this.phoneform.phone,
          areaCode: this.phoneform.areaCode,
          username: this.phoneform.name,
        };
        phoneRegister(data).then((res) => {
          localStorage.setItem("access_token", res.data.token);
          this.$store.state.access_token = res.data.token;
          localStorage.setItem("typeInfo", 0);
          this.$store.state.typeInfo = 0;
          localStorage.setItem("userId", res.data.userId);
          this.$store.state.userId = res.data.userId;
          this.$message({
            message: "注册成功！", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
              this.$router.push("/");
              // this.$router.push({ name: "BasicInformation" });
            },
          });
        });
      } else {
        if (!this.emailform.name) {
          this.$message.error("请输入昵称");
          return false;
        }
        if (!this.emailform.email) {
          this.$message.error("请输入邮箱");
          return false;
        }
        const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
        if (!reg.test(this.emailform.email)) {
          this.$message.error("请输入正确的邮箱");
          return false;
        }
        if (!this.emailform.code) {
          this.$message.error("请输入验证码");
          return false;
        }
        if (!this.emailform.password) {
          this.$message.error("请输入密码");
          return false;
        }
        if (
            this.emailform.password.length < 6 ||
            this.emailform.password.length > 20
        ) {
          this.$message.error("请输入6-20位密码");
          return false;
        }
        if (!this.emailform.requirePas) {
          this.$message.error("请再次输入密码");
          return false;
        }
        if (this.emailform.password !== this.emailform.requirePas) {
          this.$message.error("两次密码不一致");
          return false;
        }

        if (this.checked2 == false) {
          this.$message.error("请阅读并同意隐私协议和注册协议");
          return false;
        }
        let data = {
          code: this.emailform.code,
          password: this.emailform.password,
          email: this.emailform.email,
          username: this.emailform.name,
        };
        emailRegister(data).then((res) => {
          localStorage.setItem("access_token", res.data.token);
          this.$store.state.access_token = res.data.token;
          localStorage.setItem("userId", res.data.userId);
          this.$store.state.userId = res.data.userId;
          localStorage.setItem("typeInfo", 0);
          this.$store.state.typeInfo = 0;
          this.$message({
            message: "注册成功！", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
              this.$router.push("/");
              // this.$router.push({ name: "BasicInformation" });
            },
          });
        });
      }
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCode = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.setCode = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    tackBtn1() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCode1 = "获取验证码";
          this.disabled1 = false;
        } else {
          this.disabled1 = true;
          this.setCode1 = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    changePasToop2() {
      var strongRegex = new RegExp(
          "(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,20}",
          "g"
      );
      var mediumRegex = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/;
      var enoughRegex = new RegExp("(?=.{6,}).*", "g");
      if (!enoughRegex.test(this.emailform.password)) {
        this.toop2 = "请输入6-20位密码";
      } else if (strongRegex.test(this.emailform.password)) {
        this.toop2 = "密码等级：强  当前密码安全性很高";
      } else if (mediumRegex.test(this.emailform.password)) {
        this.toop2 = "密码等级：中   当前密码安全性适中";
      } else {
        this.toop2 = "密码等级：弱   当前密码等级较低  账号被盗风险较大";
      }
    },
    changePasToop1() {
      var strongRegex = new RegExp(
          "(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,20}",
          "g"
      );
      var mediumRegex = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/;
      var enoughRegex = new RegExp("(?=.{6,}).*", "g");
      if (!enoughRegex.test(this.phoneform.password)) {
        this.toop1 = "请输入6-20位密码";
      } else if (strongRegex.test(this.phoneform.password)) {
        this.toop1 = "密码等级：强  当前密码安全性很高";
      } else if (mediumRegex.test(this.phoneform.password)) {
        this.toop1 = "密码等级：中   当前密码安全性适中";
      } else {
        this.toop1 = "密码等级：弱   当前密码等级较低  账号被盗风险较大";
      }
    },
    submitEn() {
      if (this.isActive == 1) {
        if (!this.phoneform.name) {
          this.$message.error("Please enter a nickname");
          return false;
        }
        if (!this.phoneform.areaCode) {
          this.$message.error("Please select an area code");
          return false;
        }
        if (!this.phoneform.phone) {
          this.$message.error("Please enter your phone number");
          return false;
        }
        if (!this.phoneform.code) {
          this.$message.error("Please enter the verification code");
          return false;
        }
        if (!this.phoneform.password) {
          this.$message.error("Please enter password");
          return false;
        }
        if (
            this.phoneform.password.length < 6 ||
            this.phoneform.password.length > 20
        ) {
          this.$message.error("Please Enter a 6-20 digit password");
          return false;
        }
        if (!this.phoneform.requirePas) {
          this.$message.error("Please enter your password again");
          return false;
        }
        if (this.phoneform.password !== this.phoneform.requirePas) {
          this.$message.error("Two passwords do not match");
          return false;
        }
        if (this.checked1 == false) {
          this.$message.error("Please read and agree to the Privacy Agreement and Registration Agreement");
          return false;
        }
        let data = {
          code: this.phoneform.code,
          password: this.phoneform.password,
          phone: this.phoneform.phone,
          areaCode: this.phoneform.areaCode,
          username: this.phoneform.name,
        };
        phoneRegister(data).then((res) => {
          localStorage.setItem("access_token", res.data.token);
          this.$store.state.access_token = res.data.token;
          localStorage.setItem("typeInfo", 0);
          this.$store.state.typeInfo = 0;
          localStorage.setItem("userId", res.data.userId);
          this.$store.state.userId = res.data.userId;
          this.$message({
            message: "Registered successfully！", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
              this.$router.push("/");
              // this.$router.push({ name: "BasicInformation" });
            },
          });
        });
      } else {
        if (!this.emailform.name) {
          this.$message.error("Please enter a nickname");
          return false;
        }
        if (!this.emailform.email) {
          this.$message.error("Please enter email");
          return false;
        }
        const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
        if (!reg.test(this.emailform.email)) {
          this.$message.error("Please enter the correct email address");
          return false;
        }
        if (!this.emailform.code) {
          this.$message.error("Please enter the verification code");
          return false;
        }
        if (!this.emailform.password) {
          this.$message.error("Please enter password");
          return false;
        }
        if (
            this.emailform.password.length < 6 ||
            this.emailform.password.length > 20
        ) {
          this.$message.error("Please enter a 6-20 digit password");
          return false;
        }
        if (!this.emailform.requirePas) {
          this.$message.error("Please enter your password again");
          return false;
        }
        if (this.emailform.password !== this.emailform.requirePas) {
          this.$message.error("Two passwords do not match");
          return false;
        }

        if (this.checked2 == false) {
          this.$message.error("Please read and agree to the Privacy Agreement and Registration Agreement");
          return false;
        }
        let data = {
          code: this.emailform.code,
          password: this.emailform.password,
          email: this.emailform.email,
          username: this.emailform.name,
        };
        emailRegister(data).then((res) => {
          localStorage.setItem("access_token", res.data.token);
          this.$store.state.access_token = res.data.token;
          localStorage.setItem("userId", res.data.userId);
          this.$store.state.userId = res.data.userId;
          localStorage.setItem("typeInfo", 0);
          this.$store.state.typeInfo = 0;
          this.$message({
            message: "Registered successfully！", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
              this.$router.push("/");
              // this.$router.push({ name: "BasicInformation" });
            },
          });
        });
      }
    },
    tackBtnEn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCodeEn = "Get verification code";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.setCodeEn ="Try again in"+ time + " 5 seconds";
          time--;
        }
      }, 1000);
    },
    tackBtn1En() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCode1En = "Get verification code";
          this.disabled1 = false;
        } else {
          this.disabled1 = true;
          this.setCode1En ="Try again in"+ time + " 5 seconds";
          time--;
        }
      }, 1000);
    },
    changePasToop2En() {
      var strongRegex = new RegExp(
          "(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,20}",
          "g"
      );
      var mediumRegex = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/;
      var enoughRegex = new RegExp("(?=.{6,}).*", "g");
      if (!enoughRegex.test(this.emailform.password)) {
        this.toop2 = "Please enter a 6-20 digit password";
      } else if (strongRegex.test(this.emailform.password)) {
        this.toop2 = "Password level: Strong, The current password security is very high";
      } else if (mediumRegex.test(this.emailform.password)) {
        this.toop2 = "Password Level: Medium, The current password security is moderate";
      } else {
        this.toop2 = "Password level: Weak, The current password level is low. Accounts are more vulnerable to theft";
      }
    },
    changePasToop1En() {
      var strongRegex = new RegExp(
          "(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,20}",
          "g"
      );
      var mediumRegex = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/;
      var enoughRegex = new RegExp("(?=.{6,}).*", "g");
      if (!enoughRegex.test(this.phoneform.password)) {
        this.toop1 = "Please enter a 6-20 digit password";
      } else if (strongRegex.test(this.phoneform.password)) {
        this.toop1 = "Password level: Strong, The current password security is very high";
      } else if (mediumRegex.test(this.phoneform.password)) {
        this.toop1 = "Password Level: Medium, The current password security is moderate";
      } else {
        this.toop1 = "Password level: Weak, The current password level is low. Accounts are more vulnerable to theft";
      }
    },
  },
  mounted() {
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
};

</script>


<style type="text/scss" lang="scss" scoped>

.right {
  width: 384px;
  height: 570px;
  background: #fff;
  border-radius: 4px 4px 4px 4px;
  padding: 32px 32px;
  cursor: pointer;
  .tabsBar{
    height: 40px;
    width:100%;
    text-align: center;
    padding: 3px;
    margin-bottom: 20px;
    background-color: #f6f6f6;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    .barItem{
      font-size: 14px;
      width:149px;
      height: 32px;
      padding: 6px;
      margin: auto;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
    }
    .activebarCls{
      font-size: 14px;
      width:149px;
      height: 32px;
      padding-top: 6px;
      margin: auto;
      text-align: center;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: white;
      color: var(--main-color);
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #eeeeee;

    .tabsItem {
      // height: 29px;
      // line-height: 29px;
      margin: 0 26px;
      font-size: 16px;
      padding-bottom: 11px;
      color: #bbbbbb;
      cursor: pointer;
    }
    .tabsItem.activeCls {
      font-size: 16px;
      color: #333333;
      position: relative;
    }

    .tabsItem.activeCls:after {
      content: "";
      width: 100%;
      height: 3px;
      background: var(--main-color);
      position: absolute;
      left: 0;
      bottom: -1px;
    }
  }
  .tabContain {
    width: 100%;
    padding-top: 4px;
    overflow: auto;
    .items {
      display: none;
      > div {
        margin-top: 20px;
      }
      ::v-deep {
        .el-input__inner {
          line-height: 35px;
          height: 35px;
          font-size: 14px;
        }
        .input-with-select .el-input-group__prepend {
          background-color: #fff;
          height: 40px;
          border-left: 0;
          border-right: 1px solid #dcdfe6;
          width: 100%;
        }
        .el-select .el-input {
          width: 100% !important;
        }
        .el-select-dropdown__item {
          font-size: 14px;}


        .el-input.is-active .el-input__inner,
        .el-input__inner:focus {
          border-color: #dcdfe6;
        }
      }
      .el-input {
        width: 100%;
        height: 40px;
        line-height: 40px;
        border: 1px solid #d0d0d0;
        border-radius: 4px;
        display: flex;
        align-items: center;
      }
      ::v-deep .el-input__inner{
        height: 30px;
      }

      .el-input:place-holder {
        color: #bbbbbb;
      }
      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;

        ::v-deep {
          .setCode {
            width: 110px;
            height: 40px;
            text-align: center;
            border-radius: 4px;
            background: #F8F8F8;
            color:#BF0022;
            font-size: 14px;
            margin-left: 12px;
          }
          .el-button {
            border: none;
          }
        }

        .el-input {
          flex: 1;
        }
      }
      .checkbox {
        font-size: 12px;
        font-weight: 400;
        color:  #555555;
        span {
          color: var(--main-color);
          cursor: pointer;
          font-size: 12px;
        }
      }
    }

    .items.activeCls {
      display: block;
    }

    .submit {
      margin-top: 12px;
      height:40px;
      line-height: 40px;
      border-radius: 4px;
      background: var(--main-color);
      color: #fff;
      text-align: center;
      cursor: pointer;
      font-size: 16px;
    }
    .flex {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      font-size: 12px;
      line-height: 20px;
      color: #333333;
      .login span {
        color: var(--main-color);
      }
    }
  }
  .tabContain::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background-color: var(--main-color);
  }

  .tabContain::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  .tabContain::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }
  ::v-deep{
    .el-checkbox__label {
      font-size: 12px;
    }
  }
}
</style>
