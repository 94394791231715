<template>

  <div class="right">
    <div class="tabs">
      <div class="tabsItem">忘记密码</div>
    </div>
    <div class="tabContain">
      <el-input
          placeholder="请输入邮箱/手机号"
          v-model="form.account"
      ></el-input>
      <div class="flex">
        <el-input
            placeholder="请输入验证码"
            v-model="form.code"
        ></el-input>
        <el-button
            type="text"
            :disabled="disabled"
            class="setCode"
            @click="getCode(form.phone)"
        >{{ setCode }}</el-button
        >
      </div>

      <el-input
          placeholder="请输入密码"
          v-model="form.password"
          show-password
          @input="changePasToop()"
      ></el-input>
      <p class="pwsRed">{{ toop }}</p>
      <el-input
          placeholder="请再次输入密码"
          v-model="form.requirePas"
          show-password
      ></el-input>
      <div class="submit" @click="submit()">确认</div>
      <div class="login" @click="login()">返回登录>></div>
    </div>
  </div>

</template>


<script>

import {emailCode, forgetPassword, phoneCode} from "@/api/user";

export default {
  name: "forgetPassowrd",
  data() {
    return {
      isMobile:false,
      languageSelect:'',
      disabled: false,
      form: {
        account: "",
        code: "",
        password: "",
        requirePas: "",
      },
      setCode: "发送验证码",
      setCodeEn: "Verification code",
      toop: "",
    };
  },
  computed: {},
  mounted() {
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }

  },
  methods: {
    tabsChange() {
      this.isActive = this.isActive == 1 ? 2 : 1;
    },
    //获取验证码 并只验证手机号 是否正确
    //获取验证码 并只验证手机号 是否正确
    getCode() {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      const reg1 = /^\d{6,13}$/;
      if (!this.form.account) {
        this.$message.error("请输入账号");
        return false;
      }
      if (!reg1.test(this.form.account) && !reg.test(this.form.account)) {
        this.$message.error("请输入正确的账号");
        return false;
      }
      if (reg.test(this.form.account)) {
        let data = {
          email: this.form.account,
          event: "resetpwd",
        }
        emailCode(data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.tackBtn(); //验证码倒数60秒
        });
      } else {
        let data = {
          phone: this.form.account,
          event: "resetpwd",
        };
        phoneCode(data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.tackBtn(); //验证码倒数60秒
        });
      }
    },
    getCodeEn() {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      const reg1 = /^\d{6,13}$/;
      if (!this.form.account) {
        this.$message.error("Please enter your account number");
        return false;
      }
      if (!reg1.test(this.form.account) && !reg.test(this.form.account)) {
        this.$message.error("Please enter the correct account number");
        return false;
      }
      if (reg.test(this.form.account)) {
        let data = {
          email: this.form.account,
          event: "resetpwd",
        }
        emailCode(data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.tackBtnEn(); //验证码倒数60秒
        });
      } else {
        let data = {
          phone: this.form.account,
          event: "resetpwd",
        };
        phoneCode(data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.tackBtnEn(); //验证码倒数60秒
        });
      }
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCode = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.setCode = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    tackBtnEn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCodeEn = "Get verification code";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.setCodeEn ="Try again in "+ time + " seconds";
          time--;
        }
      }, 1000);
    },
    login(){
     this.$emit("login")
    },
    submit() {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      const reg1 = /^\d{6,13}$/;
      if (!this.form.account) {
        this.$message.error("请输入账号");
        return false;
      }
      if (!reg1.test(this.form.account) && !reg.test(this.form.account)) {
        this.$message.error("请输入正确的账号");
        return false;
      }
      if (!this.form.code) {
        this.$message.error("请输入验证码");
        return false;
      }
      if (!this.form.password) {
        this.$message.error("请输入密码");
        return false;
      }
      if (!this.form.requirePas) {
        this.$message.error("请再次输入密码");
        return false;
      }
      if (this.form.password !== this.form.requirePas) {
        this.$message.error("两次密码不一致");
        return false;
      }
      let data = {
        username: this.form.account,
        code: this.form.code,
        password: this.form.password,
      };
      forgetPassword(data).then(() => {
        this.$message({
          message: "修改成功！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            this.$router.push("/login");
          },
        });
      });
    },
    submitEn() {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      const reg1 = /^\d{6,13}$/;
      if (!this.form.account) {
        this.$message.error("Please enter your account number");
        return false;
      }
      if (!reg1.test(this.form.account) && !reg.test(this.form.account)) {
        this.$message.error("Please enter the correct account number");
        return false;
      }
      if (!this.form.code) {
        this.$message.error("Please enter the verification code");
        return false;
      }
      if (!this.form.password) {
        this.$message.error("Please enter password");
        return false;
      }
      if (!this.form.requirePas) {
        this.$message.error("Please enter your password again");
        return false;
      }
      if (this.form.password !== this.form.requirePas) {
        this.$message.error("Two passwords do not match");
        return false;
      }
      let data = {
        username: this.form.account,
        code: this.form.code,
        password: this.form.password,
      };
      forgetPassword(data).then(() => {
        this.$message({
          message: "Modified successfully！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            this.$router.push("/login");
          },
        });
      });
    },
    changePasToop() {
      var strongRegex = new RegExp(
          "(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,20}",
          "g"
      );
      var mediumRegex = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/;
      var enoughRegex = new RegExp("(?=.{6,}).*", "g");
      if (!enoughRegex.test(this.form.password)) {
        this.toop = "请输入6-20位密码";
      } else if (strongRegex.test(this.form.password)) {
        this.toop = "密码等级：强  当前密码安全性很高";
      } else if (mediumRegex.test(this.form.password)) {
        this.toop = "密码等级：中   当前密码安全性适中";
      } else {
        this.toop = "密码等级：弱   当前密码等级较低  账号被盗风险较大";
      }
    },
    changePasToopEn() {
      var strongRegex = new RegExp(
          "(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,20}",
          "g"
      );
      var mediumRegex = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/;
      var enoughRegex = new RegExp("(?=.{6,}).*", "g");
      if (!enoughRegex.test(this.form.password)) {
        this.toop = "Please enter a 6-20 digit password";
      } else if (strongRegex.test(this.form.password)) {
        this.toop = "Password level: Strong, The current password security is very high";
      } else if (mediumRegex.test(this.form.password)) {
        this.toop = "Password Level: Medium, The current password security is moderate";
      } else {
        this.toop = "Password level: Weak, The current password level is low. Accounts are more vulnerable to theft";
      }
    },
  },
};

</script>


<style type="text/scss" lang="scss" scoped>

.right {
  width: 384px;
  height: 440px;
  background: #fff;
  border-radius: 4px;
  padding: 30px 32px;

  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;

    .tabsItem {
      width: 72px;
      height: 22px;
      font-size: 18px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #BF0022;
      line-height: 22px;
    }
  }
  .tabContain {
    width: 100%;
    padding-top: 4px;
    > div {
      margin-top: 20px;
    }
    ::v-deep {
      .el-input__inner {
        line-height: 35px;
        height: 35px;
        font-size: 14px;
      }
      .input-with-select .el-input-group__prepend {
        background-color: #fff;
        height: 40px;
        border-left: 0;
        border-right: 1px solid #dcdfe6;
        width: 100%;
      }
      .el-select .el-input {
        width: 100% !important;
      }
      .el-select-dropdown__item {
        font-size: 14px;}


      .el-input.is-active .el-input__inner,
      .el-input__inner:focus {
        border-color: #dcdfe6;
      }
    }
    .el-input {
      width: 100%;
      height: 40px;
      line-height: 35px;
      border: 1px solid #d0d0d0;
      border-radius: 4px;
      // input {
      //   width: 100%;
      //   height: 100%;
      //   padding: 0 25px;
      //   box-sizing: border-box;
      //   display: block;
      //   font-size: 16px;
      //   color: #666666;
      //   // border-radius: 4px;
      // }
    }
    .el-input:place-holder {
      color: #bbbbbb;
    }
    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      ::v-deep {
        .setCode {
          width: 110px;
          height: 40px;
          background: #F8F8F8;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          // line-height: 50px;
          text-align: center;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #BF0022;
          line-height: 14px;
          margin-left: 12px;

        }
        .el-button{
          border: none;
        }
      }

      .el-input {
        flex: 1;
      }
    }

    .submit {
      margin-top: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
      background: var(--main-color);
      color: #fff;
      text-align: center;
      cursor: pointer;
      font-size: 16px;
    }
    .login {
      width: 100%;
      text-align: center;
      color: var(--main-color);
      font-size: 12px;
      display: block;
      margin-top: 10px;
    }
  }
}
</style>
