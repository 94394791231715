<template>
  <div>
    <div v-if="isMobile===false">
      <el-dialog
          :visible.sync="dialogVisible"
          width="644px"
          :before-close="handleClose"
          top="15vh"
      >
        <div class="login">
          <div class="left">
            <div>
              <span class="name1">欢迎注册</span>
              <span class="name2">油气人才</span>
            </div>
            <div class="name3">好工作就在这里</div>
            <div class="name4"></div>

          </div>
          <div class="right">
            <RegisterDemoIn
                @login="login"
            ></RegisterDemoIn>

          </div>

        </div>
      </el-dialog>
    </div>
  </div>

</template>
<script>
import RegisterDemoIn from "@/views/userlog/RegisterDemoIn.vue";
export default {
  components:{
   RegisterDemoIn
  },
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
  }, data(){
    return{
      isMobile:false,
      languageSelect:''
    }
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }

  },
  methods: {
    login(){
      this.$emit('login')
    },
    handleClose() {
      this.$emit('hideDialog')
    },
  },
};
</script>
<style scoped lang="scss">
.login{
  width: 644px;
  height: 570px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  display: flex;
  .left{
    width: 260px;
    height: 570px;
    background: linear-gradient(157deg, #FBCFD7 0%, #FFE9EE 50%, #FFFFFF 100%);
    opacity: 1;
    padding:72px 30px 0 30px ;
    position: relative;
    .name1{
      height: 20px;
      font-size: 20px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      letter-spacing: 4px;
      text-shadow: 0px 1px 0px rgba(191,0,34,0.2);
    }
    .name2{
      height: 20px;
      font-size: 22px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #BF0022;
      line-height: 20px;
      letter-spacing: 4px;
      text-shadow: 0px 1px 0px rgba(191,0,34,0.2);
    }
    .name3{
      margin-top: 18px;
      width: 100%;
      text-align: center;
      height: 20px;
      font-size: 14px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      text-shadow: 0px 1px 0px rgba(191,0,34,0.2);
    }
    .name4{
      position: absolute;
      right: 0;
      bottom: 138px;
      width: 260px;
      height: 208px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      background-image: url("../../assets/job.png");
      background-size:contain;
      background-position:center;
      background-repeat:no-repeat
    }

  }
  .right{
    width: 384px;
    height: 570px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }
}
::v-deep {
  .el-dialog__header {

    .el-dialog__title {
      padding: 0;
    }
  }
  .el-dialog__header{
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  .el-button {
    width: 100px;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    padding: 0;
    a {
      display: block;
    }
  }
  .el-button--primary {
    border: 1px solid var(--main-color);
    background: var(--main-color);
    color: #fff;
  }
  .el-button:focus,
  .el-button:hover {
    color: var(--main-color);
    border-color: var(--main-color);
    background-color: #fff;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: var(--main-color);
    border-color: var(--main-color);
    color: #fff;
  }
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: var(--main-color);
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #FFFFFF;

  }
  .el-dialog__headerbtn {
    position: absolute;
    top: -21px;
    right: 0;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    color:black;
  }
}
</style>
