/*
 * @Author: 自由命名
 * @Date: 2024-01-22 09:57:12
 * @LastEditors: 自由命名
 * @LastEditTime: 2024-02-01 13:57:10
 * @FilePath: \talent-front-end\src\main.js
 * @filePurpose:
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import utils from './utils/config'
import md5 from 'js-md5'
import dataV from '@jiaminghi/data-view'
import {
  Pagination
} from 'element-ui';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
// 百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'CjyRuM3seEFNzZDvq5CgwDQYG36Fa1cp'
})
// 高德地图
import VueAMap from 'vue-amap';
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
    key:  'fbdb4d82a56fb481578cedf70451078e',
    plugin: [
        "AMap.Autocomplete", //输入提示插件
        "AMap.PlaceSearch", //POI搜索插件
        "AMap.Scale", //右下角缩略图插件 比例尺
        "AMap.OverView", //地图鹰眼插件
        "AMap.ToolBar", //地图工具条
        "AMap.Geolocation", //定位控件，用来获取和展示用户主机所在的经纬度位置
        "AMap.Geocoder"// 逆地理编码,通过经纬度获取地址所在位置详细信息
        // 根据需求选用
    ],
    uiVersion: "1.0", // 地图ui版本
    lang:'zh',
    v : '1.4.4' // amap版本
})
// 只在开发环境下初始化 VConsole
if (localStorage.getItem('language') === 'en') {
  locale.use(lang);
  Vue.use(Pagination);
}
import {
  getUser,
  // insertUserAgent
} from "@/api/user";

Vue.config.productionTip = false
Vue.use(dataV)
ElementUI.Dialog.props.closeOnClickModal.default = false
Vue.prototype.$axios = axios
Vue.prototype.$utils = utils;
import common from './utils/common'
Vue.use(common);
Vue.prototype.$md5 = md5;


Vue.use(ElementUI)

router.beforeEach((to, from, next) => {
  console.log('进入页面')
  console.log(to.query.code, !store.state.access_token)
  //需要权限
  if (to.query.code && !store.state.access_token) {
    let path = to.fullPath.split("?code")[0]
    if (to.query.language || to.query.positionId) {
      path = to.fullPath.split("&code")[0]
    }
    let data = {
      code: to.query.code,
      url: utils.baseURL + path,
    };
    console.log(data, 'data')
    getUser(data)
      .then((res) => {
        console.log('useridd', res.data)
        localStorage.setItem("access_token", res.data.token);
        localStorage.setItem("Zyaccess_token", res.data.access_token);
        localStorage.setItem("idass", res.data.idass);
        localStorage.setItem("userId", res.data.userId);
        store.state.userId = res.data.userId;
        store.state.access_token = res.data.token;
        store.state.idass = res.data.idass
        let data = {
          userAgent: localStorage.getItem('murmur'),
          idass: store.state.idass,
        };
        // insertUserAgent(data)
        const insertUserAgent = Vue.prototype.$axios;
        insertUserAgent.post("https://www.oilgasinfoai.com/auth/oauth/insertUserAgent", data)
          .then((res) => {
            console.log(res)
            next({
              path: path,
            });
            // next();
          })
        // window.location.replace(path);
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    next();
  }
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
