var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[(_vm.home===true||_vm.home==='true')?_c('div',{staticClass:"footer"},[_c('div',{staticStyle:{"width":"20%"},on:{"click":function($event){return _vm.homeback()}}},[_vm._m(0),_c('div',{staticStyle:{"color":"var(--main-color)"}},[_vm._v(" 首页 ")])]),_vm._m(1),_vm._m(2),_c('div',{staticStyle:{"width":"20%"},on:{"click":function($event){return _vm.tackConstruct()}}},[_vm._m(3)]),_c('div',{staticStyle:{"width":"20%"},on:{"click":function($event){return _vm.tack()}}},[_vm._m(4),_c('div',[_vm._v(" 我的 ")])])]):_c('div',{staticClass:"footer"},[_c('div',{staticStyle:{"width":"20%"},on:{"click":function($event){return _vm.homeback()}}},[_vm._m(5),_c('div',[_vm._v(" 首页 ")])]),_vm._m(6),_vm._m(7),_c('div',{staticStyle:{"width":"20%"},on:{"click":function($event){return _vm.tackConstruct()}}},[_vm._m(8)]),_c('div',{staticStyle:{"width":"20%","color":"var(--main-color)"},on:{"click":function($event){return _vm.tack()}}},[_vm._m(9),_c('div',[_vm._v(" 我的 ")])])])]),_c('PClogin',{attrs:{"dialogVisible":_vm.cancleVisible},on:{"hide":_vm.hide}}),_c('construct',{attrs:{"dialogVisible":_vm.constructVisible},on:{"hide":_vm.hideConstruct}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"communityStyle"},[_c('img',{attrs:{"src":require("@/assets/homeLogo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"20%"}},[_c('a',{attrs:{"href":"https://www.oilgascommunity.com/"}},[_c('div',{staticClass:"communityStyle"},[_c('img',{attrs:{"src":require("@/assets/community.png")}})]),_c('div',[_vm._v(" 社区 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"20%"}},[_c('a',{attrs:{"href":"https://cn.oilgasmall.com/"}},[_c('div',{staticClass:"communityStyle"},[_c('img',{attrs:{"src":require("@/assets/exhind.png")}})]),_c('div',[_vm._v(" 展馆 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.oilgasinfoai.com/"}},[_c('div',{staticClass:"communityStyle"},[_c('img',{attrs:{"src":require("@/assets/recruite.png")}})]),_c('div',[_vm._v(" 资讯 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"communityStyle"},[_c('img',{attrs:{"src":require("@/assets/myDispear.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"communityStyle"},[_c('img',{attrs:{"src":require("@/assets/homeDispeare.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"20%"}},[_c('a',{attrs:{"href":"https://www.oilgascommunity.com/"}},[_c('div',{staticClass:"communityStyle"},[_c('img',{attrs:{"src":require("@/assets/community.png")}})]),_c('div',[_vm._v(" 社区 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"20%"}},[_c('a',{attrs:{"href":"https://cn.oilgasmall.com/"}},[_c('div',{staticClass:"communityStyle"},[_c('img',{attrs:{"src":require("@/assets/exhind.png")}})]),_c('div',[_vm._v(" 展馆 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.oilgasinfoai.com/"}},[_c('div',{staticClass:"communityStyle"},[_c('img',{attrs:{"src":require("@/assets/recruite.png")}})]),_c('div',[_vm._v(" 资讯 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"communityStyle"},[_c('img',{attrs:{"src":require("@/assets/mySelect.png")}})])
}]

export { render, staticRenderFns }