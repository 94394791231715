<template>
  <el-footer height="auto" id="footer">
    <div class="footer centerWidth">
      <div class="left">
        <div class="item"  v-for="(item, index) in foot_navs" :key="index">
          <div v-if="languageSelect==='zh'">
            <div   class="title" @click="link(item.url)">
              {{ item.title }}
            </div>
            <div class="list">
              <div
                  class="link"
                  v-for="(items, indexs) in item.child"
                  :key="indexs"
                  @click="link(items.url)"
              >
                {{ items.title }}
              </div>
            </div>
          </div>
          <div v-if="languageSelect==='en'">
            <div   class="title" style="font-size: 14px" @click="link(item.urlEn)">
              {{ item.titleEn }}
            </div>
            <div class="list">
              <div
                  class="link"
                  v-for="(items, indexs) in item.child"
                  :key="indexs"
                  @click="link(items.urlEn)"
              >
                {{ items.titleEn }}
              </div>
            </div>
          </div>

        </div>
        <div class="item content" v-if="languageSelect==='zh'">
          <div class="title">联系我们</div>
          <div class="list">
            <div v-if="contentType.email">邮箱：{{ contentType.email }}</div>
            <div v-if="contentType.address">
              地址：{{ contentType.address }}
            </div>
          </div>
        </div>
        <div class="item content" v-if="languageSelect==='en'">
          <div class="title" style="font-size: 14px">Contact us</div>
          <div class="list">
            <div v-if="contentType.phone">Phone：{{ contentType.phone }}</div>
            <div v-if="contentType.email">E-mail：{{ contentType.email }}</div>
            <div v-if="contentType.address">
              Address：No. 11 Yingbin South Road, Huairou District, Beijing
            </div>
          </div>
        </div>
      </div>
      <div class="right" v-if="languageSelect==='zh'">
          <div class="" v-for="(item, index) in qrcode" :key="index">
            <img :src="baseURL + item.img" alt="" />
            <span>{{ item.name }}</span>
          </div>
      </div>
      <div class="right" v-if="languageSelect==='en'">
          <div class="" v-for="(item, index) in qrcode" :key="index">
            <img :src="baseURL + item.img" alt="" />
            <span>{{ item.nameEn }}</span>
        </div>

      </div>
    </div>
    <div class="foot centerWidth"  v-if="languageSelect==='zh'">
      <div class="flex">
        <p>北京市怀柔区迎宾南路11号</p>
        <span> | </span>
        <p>北京石由同学信息咨询有限公司版权所有</p>
        <span> | </span>
        <p class="red">
          <a href="https://beian.miit.gov.cn/">京ICP备2022034763号-6</a>
        </p>
      </div>
    </div>
    <div class="foot centerWidth"  v-if="languageSelect==='en'">
      <div class="flex">
        <p>No. 11 Yingbin South Road, Huairou District, Beijing</p>
        <span> | </span>
        <p>Copyright Beijing Shiyou Classmate Information Consulting Co., Ltd</p>
        <span> | </span>
        <p class="red">
          <a href="https://beian.miit.gov.cn/">京ICP备2022034763号-6</a>
        </p>
      </div>
      <!-- <div
        style="
          width: fit-content;
          font-size: 12px;
          margin: 0 auto;
          padding: 5px 0px 10px;
        "
      >
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502053484"
          style="display: inline-block; text-decoration: none"
        >
          <img loading="lazy" src="@/assets/beian.png" style="float: left; width: 17px" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            京公网安备 11010502053484号
          </p></a
        >
      </div> -->
    </div>
  </el-footer>
</template>
<script>
import {
  // // columnNav,
  // columnContact, columnQrcode
} from "@/api/index";
export default {
  data() {
    return {
      languageSelect:'',
      baseURL: this.$utils.baseURL,
      foot_navs: [],
      token:localStorage.getItem("access_token"),
      contentType: {
        phone: "",
        email: "",
        address: "",
      },
      qrcode: [],
    };
  },
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }
    this.GetColumnNav();
    this.GetColumnContact();
    this.GetColumnQrcode();
  },
  methods: {
    GetColumnNav() {
      this.$axios.get("/system/job_column/column" , {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
      })
          .then((res) => {
            // console.log("--------")
            // console.log(res.data.data)
            this.foot_navs = res.data.data;
          }).catch(error=>{
        console.log(error)
      })
    },
    GetColumnContact() {
      this.$axios.get("/system/job_contact/list" , {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
      })
          .then((res) => {
            // console.log("--------")
            // console.log(res.data.rows[0])
            this.contentType =res.data.rows[0]
          }).catch(error=>{
        console.log(error)
      })
    },
    GetColumnQrcode() {
      this.$axios.get("/system/job_qrcode/list" , {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
      })
          .then((res) => {
            this.qrcode = res.data.rows

          }).catch(error=>{
        console.log(error)
      })

    },
    link(url) {
      if (url) window.open(url, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
#footer{
  bottom: 0;
  width: 100%;
}
.centerWidth{
  width: 1200px;
  margin:0 auto;
}
.el-footer {
  background: #1c1c1c;
  color: #999999;
  .footer {
    padding-top: 60px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    .left {
      flex: 1;
      width: 0;
      // display: grid;
      // grid-template-columns: auto auto auto 320px;
      column-gap: 70px;
      text-align: left;
      font-size: 12px;
      display: flex;
      justify-content: flex-start;
      .item {
        .title {
          display: block;
          color: #fff;
          font-size: 18px;
          margin-bottom: 8px;
          cursor: pointer;
        }
        .list {
          div,
          a {
            display: block;
            margin-bottom: 14px;
          }
          .link {
            cursor: pointer;
          }
        }
      }
      .content {
        width: 400px;
      }
    }
    .right {
      margin-left: 70px;
      display: flex;
      div {
        width: 110px;
        font-size: 14px;
        color: #999999;
        text-align: center;
        img {
          width: 110px;
          height: 110px;
          margin-bottom: 10px;
        }
      }
      div:last-child {
        margin-left: 40px;
      }
    }
  }
  .foot {
    text-align: center;
    padding: 10px 0px 10px 0px;
    border-top: 1px solid #373b4f;
    .flex {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      text-align: center;
      width: fit-content;
      color: #999999;
      margin: 0;
    }

    span {
      padding: 0 10px;
      color: #999999;
      font-size: 12px;
    }

    p:nth-child(1),
    p:nth-child(3) {
      font-size: 12px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      line-height: 30px;
    }

    p.red {
      color: #ef3e4f;
      /* font-weight: bold; */
      font-size: 12px;
      line-height: 30px;
    }

    p.red a {
      color: #999999;
    }
  }
}
</style>
