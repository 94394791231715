/*
 * @Author: 自由命名
 * @Date: 2024-01-22 09:57:12
 * @LastEditors: 自由命名
 * @LastEditTime: 2024-02-01 15:49:24
 * @FilePath: \talent-front-end\src\store\index.js
 * @filePurpose: 
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    access_token: localStorage.getItem('access_token') || '',
    user: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
    idass: localStorage.getItem('idass') || '',
    statistic: '',
    userId: localStorage.getItem('userId') || '',
    typeInfo: localStorage.getItem('typeInfo') || '',
    MessageCount: 0,
    navList: [],
    language: 'zh',
    cityCode: [],
  },
  mutations: {},
  actions: {},
  modules: {}
})