<template>
  <div>
    <div v-if="isMobile === false">
      <div class="position">
    <div class="fontStyle">
      推荐岗位
    </div>
    <div class="jobCard">
      <li
          v-for="(jobs, index) in job"
          :key="index"
      >
        <div class="card" @click="personal(jobs)">
          <div class="jobName">
            <span class="jobName1">{{jobs.positionName}}</span>
            <span class="jobName2" style="">{{jobs.positionSalary}}</span>
          </div>
          <div class="jobStyle">
            <span class="jobStyle1">{{jobs.positionAddress}}</span>
          </div>
          <div class="jobStylelater">
            <span class="jobStyle1">{{jobs.positionTime}}</span>
            <span class="jobStyle2">{{jobs.positionEducation}}</span>
            <span class="jobStyle2">{{jobs.positionCategory}}</span>
          </div>
          <div class="jobSpan"></div>
          <div class="company">
            <div class="comLogo">
              <img :src="baseURL + jobs.companyAvatar" alt="" />
            </div>
            <div class="comName">

              <div slot="reference" class="jobNamecompany">
                {{jobs.companyName}}
              </div>
              <div style="font-size: 13px;font-weight: 400;color: #666666;margin-top:1.2vh;display: flex; ">
                <div style="display:flex;;overflow: hidden;white-space: nowrap;text-align:justify;max-width: 125px">
                  <div  v-for="(item, index) in jobs.companyIndustry" :key="item.id">
                    <span>{{ item }}</span>
                    <span v-if="index < jobs.companyIndustry.length - 1">、</span>
                  </div>
                </div>
                <span>...</span>
                <span style="margin-left: 10px">{{jobs.companyFinancing}}</span>
                <span style="margin-left: 10px">{{jobs.companyScale}}</span>
              </div>

            </div>
          </div>




        </div>


      </li>


    </div>

    <div class="more">
      <el-button class="moreJOb"  @click="moreJob()">查看更多</el-button>
    </div>



  </div>
    </div>
    <div v-else>
      <div class="positionMobile">
        <div class="fontStyle">
          推荐岗位
        </div>
        <div class="jobCard">
          <li
              v-for="(jobs, index) in job"
              :key="index"
          >
            <div class="card" @click="personal(jobs)">
              <div class="jobName">
                <span class="jobName1">{{jobs.positionName}}</span>
                <span class="jobName2" style="">{{jobs.positionSalary}}</span>
              </div>
              <div class="jobStyle">
                <span class="jobStyle1">{{jobs.positionCategory}}</span>
              </div>
              <div class="jobStylelater">
                <span class="jobStyle1">{{jobs.positionAddress}}</span>
                <span class="jobStyle2">{{jobs.positionTime}}</span>
                <span class="jobStyle2">{{jobs.positionEducation}}</span>
              </div>

              <div class="company">
                <div class="comLogo">
                  <img :src="baseURL + jobs.interviewAvatar" alt="" />
                </div>
                <div class="comName">
                  <div style="font-size: 12px;font-weight: 400;color: #7F7F7F;margin-top:1.2vh;display: flex; ">
                    <span>{{jobs.interviewName}}</span>
                    <span style="margin-left: 6px">{{jobs.interviewDuties}}</span>
                  </div>

                </div>


              </div>
              <div v-if="index<job.length-1" class="jobSpan"></div>

            </div>
          </li>
        </div>

      </div>
    </div>
  </div>

</template>

<script>

export default {

  name: "",

  props: [],

  components: {},

  data() {

    return {
      isMobile:false,
      baseURL: this.$utils.baseURL,
      token:localStorage.getItem("access_token"),
      job:[]
    }

  },

  methods: {
    moreJob(){
      this.$router.push({
        path: "/recruit",

      });
    },
    personal(list) {
      console.log(list)
      this.$router.push({
        path: "/positionDetail",
        query: {
          positionId: list.positionId,
        },
      });
    },
    jobcard(){
      this.$axios.get("/system/position/select_list" , {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
      })
          .then(async (res) => {
            this.job = res.data.rows;


            for (var i = 0; i < this.job.length; i++) {
              // console.log(this.job[i].companyIndustry);
              this.job[i].companyIndustry = JSON.parse(this.job[i].companyIndustry)
              if(this.job[i].positionTime!==null){
                this.job[i].positionTime = await this.getTime(this.job[i].positionTime);
              }
              if(this.job[i].positionEducation!==null){
                this.job[i].positionEducation = await this.getEducation(this.job[i].positionEducation);
              }
              if(this.job[i].positionCategory!==null){
                this.job[i].positionCategory = await this.getCategory(this.job[i].positionCategory);
              }
              if (this.isMobile === true) {
                this.handleSex(this.job[i])
              }


            }
          }).catch(error=>{
        console.log(error)
      })
    },
    getTime(item) {
      return this.$axios
          .get("/system/time/" + item, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.content);
            return res.data.data.content;
          })
          .catch((error) => {
            console.log(error);
            throw error; // 抛出错误，这将导致Promise被reject
          });
    },
    getEducation(item) {
      return this.$axios
          .get("/system/education/" + item, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.content);
            return res.data.data.content;
          })
          .catch((error) => {
            console.log(error);
            throw error; // 抛出错误，这将导致Promise被reject
          });
    },
    getCategory(item) {
      return this.$axios
          .get("/system/position_category/" + item, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.content);
            return res.data.data.content;
          })
          .catch((error) => {
            console.log(error);
            throw error; // 抛出错误，这将导致Promise被reject
          });
    },
    handleSex(item){
      if(item.sex!==null){
        if(item.sex==0){
          item.interviewName=item.interviewName[0]+"女士"
        }
        else{
          item.interviewName=item.interviewName[0]+"先生"

        }
      }

    },
    communicate(){

    }
  },

  mounted() {
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;


  },
  created() {
    this.jobcard()
  }

}

</script>




<style type="text/scss" lang="scss" scoped>

.position{
  width: 100vw;
  min-height:5vh;
  height: auto;
  .fontStyle{
    width: 128px;
    margin:65px auto 0 auto;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    line-height: 32px;
  }
  .jobCard{
    width: 1200px;
    margin:32px auto 0 auto;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    gap: 15px;
    list-style: none;
    .card{
      cursor: pointer;
      padding: 20px 20px;
      //height:16.2vh;
      height: fit-content;
      background: #FFFFFF;
      //border: 1px solid black;
      box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.15);
      border-radius: 7px;
      opacity: 1;
      position: relative;
      .jobName{
        display: flex;
        width: 347px;
        position: relative;
        .nameStyle{
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          font-family: arial, sans-serif, "Microsoft Yahei";
          line-height: 16px;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .jobName1{

        width: 80%;
        white-space: nowrap; /* 防止文本换行 */
        overflow: hidden;    /* 隐藏溢出的部分 */
        text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
        font-size: 16px;font-weight: 500;color: #333333;
      }

      .jobName2{
        position: absolute;
        font-size: 18px;
        font-weight: 500;
        color: #bf0022;
        right: 0;
      }

      .jobStyle{
        display: flex;
        margin-top: 10px;
        width: 347px;
        .jobStyle1{
          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden;    /* 隐藏溢出的部分 */
          text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
          font-size: 12px;
          font-weight: 400;
          color: #7F7F7F;
          //background-color: #F5F5F5;
        }


      }
      .jobStylelater{
        display: flex;
        margin-top: 10px;
        width: fit-content;
        .jobStyle1{
          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden;    /* 隐藏溢出的部分 */
          text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          background-color:#F2F4F7;;
          padding:4.9px 10px;
          border-radius: 2px;
        }

        .jobStyle2
        {
          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden;    /* 隐藏溢出的部分 */
          text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          background-color:#F2F4F7;;
          padding:4.9px 10px;
          margin-left:8px;
          border-radius: 2px;
        }

      }
      .jobSpan{
        width: 100%;
        margin: 18px auto;
        height: 1px;
        background: #d5d5d5;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
      .company{
        width: 100%;
        margin: 1vh auto;
        display: flex;
        position: relative;
        .comLogo{
          width: 48px;
          height: 48px;
          border-radius: 6px;
          opacity: 1;
          border: 1px solid rgba(0,0,0,0.02);
        }

        .comName{
          margin-left: 16px;
          .jobNamecompany{
            font-size: 14px;
            font-weight: 500; color: #333333; max-width: 10.3vw; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
          }


        }
        .comButton{
          position: absolute;
          bottom: 0;
          right: 0;
          .companyBut{
            width: 92px;
            height: 32px;
            border: none;
            font-size: 14px;
            font-weight: 400;
            color: #bf0022;
            background: #FAEBEE;
            border-radius: 15px 15px 15px 15px;
            opacity: 1;
          }
          .companyBut:hover {
            /* 此处是鼠标悬浮时的样式 */
            color: #FFFFFF;
            background: #bf0022;
            /* 你可以添加其他的样式以突出悬浮状态 */
          }


          ::v-deep .el-button{
            padding: 5px 10px;
          }
        }
      }
    }
    .card:hover {
      transform: scale(1.05);
      transition: transform 0.3s ease-in-out; /* Adding transition effect */
      .jobName1{
        color:#bf0022;
        transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
      }
      .jobName2{
        transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
      }
      .comName{
        .jobNamecompany{

          color: #bf0022;
        }
      }
    }

  }
  .more {
    width: 164px;
    margin: 45px auto 30px auto;
    border-radius: 18px;

    .moreJOb {
      border-radius: 53px;
      width: 100%;
      text-align: center;
      height: 44px;
      background: #ebd9df;
      opacity: 1;
      color: #BF0022;
      border: none;
    }

    .moreJOb:hover {
      background: #BF0022;
      color: #FFFFFF;
    }
  }



}
.positionMobile{
  width: 100vw;
  min-height:5vh;
  height: auto;
  background-color: white;
  margin-top: 20px;
  padding:20px 20px 0 20px;
  .fontStyle{
    width: fit-content;

    text-align: left;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 20px;
  }
  .jobCard{
    width: fit-content;
    margin:20px auto 0 auto;
    display: grid;
    grid-template-columns: minmax(0, 1fr);

    list-style: none;
    .card{
      cursor: pointer;
      padding: 10px 0;
      //height:16.2vh;
      height: fit-content;
      background: #FFFFFF;
      //border-bottom: #d5d5d5 1px solid;
      opacity: 1;
      position: relative;
      .jobName{
        display: flex;
        width: 100%;
        position: relative;
        .nameStyle{
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          font-family: arial, sans-serif, "Microsoft Yahei";
          line-height: 16px;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .jobName1{

        width: 80%;
        white-space: nowrap; /* 防止文本换行 */
        overflow: hidden;    /* 隐藏溢出的部分 */
        text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
        font-size: 14px;font-weight: 500;color: #333333;
      }

      .jobName2{
        position: absolute; font-size: 14px;font-weight: 500;color: #bf0022;right: 0
      }

      .jobStyle{
        display: flex;
        margin-top: 10px;
        width: 347px;
        .jobStyle1{
          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden;    /* 隐藏溢出的部分 */
          text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
          font-size: 12px;
          font-weight: 400;
          color: #7F7F7F;
          //background-color: #F5F5F5;
        }


      }
      .jobStylelater{
        display: flex;
        margin-top: 10px;
        width: fit-content;
        .jobStyle1{
          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden;    /* 隐藏溢出的部分 */
          text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          background-color:#F2F4F7;;
          padding:4.9px 10px;
          border-radius: 2px;
        }

        .jobStyle2
        {
          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden;    /* 隐藏溢出的部分 */
          text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          background-color:#F2F4F7;;
          padding:4.9px 10px;
          margin-left:8px;
          border-radius: 2px;
        }
      }
      .jobSpan{
        width: 100%;
        margin: 20px auto 0 auto;
        height: 1px;
        background: #d5d5d5;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
      .company{
        width: 100%;
        margin: 10px auto 10px auto;
        display: flex;
        position: relative;
        .comLogo{
          width: 40px;
          height: 40px;
          border-radius: 50%;
          opacity: 1;
          border: 1px solid rgba(0,0,0,0.02);
        }

        .comName{
          margin-left: 10px;
          margin-top: 3px;
          .jobNamecompany{
            font-size: 14px;
            font-weight: 500; color: #333333; max-width: 10.3vw; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
          }


        }
        .comButton{
          position: absolute;
          bottom: 0;
          right: 0;
          .companyBut{
            width: 92px;
            height: 32px;
            border: none;
            font-size: 14px;
            font-weight: 400;
            color: #bf0022;
            background: #FAEBEE;
            border-radius: 15px 15px 15px 15px;
            opacity: 1;
          }
          .companyBut:hover {
            /* 此处是鼠标悬浮时的样式 */
            color: #FFFFFF;
            background: #bf0022;
            /* 你可以添加其他的样式以突出悬浮状态 */
          }


          ::v-deep .el-button{
            padding: 5px 10px;
          }
        }
      }
    }
    .card:hover {
      transform: scale(1.05);
      transition: transform 0.3s ease-in-out; /* Adding transition effect */
      .jobName1{
        color:#bf0022;
        transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
      }
      .jobName2{
        transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
      }
      .comName{
        .jobNamecompany{

          color: #bf0022;
        }
      }
    }

  }
  .more {
    width: 164px;
    margin: 45px auto 30px auto;
    border-radius: 18px;

    .moreJOb {
      border-radius: 53px;
      width: 100%;
      text-align: center;
      height: 44px;
      background: #ebd9df;
      opacity: 1;
      color: #BF0022;
      border: none;
    }

    .moreJOb:hover {
      background: #BF0022;
      color: #FFFFFF;
    }
  }



}
.popover-content {
  text-align: center;
}

</style>
