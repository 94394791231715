<template>

  <div>
    <el-popover
        placement="bottom"
        width="45"
        style="text-align: center"
        trigger="hover">

        <img loading="lazy" src="@/assets/scan.jpeg" alt="二维码" style="width: 120px; height: auto; display: block; text-align: center;" />

      <el-button style="border: none;background: transparent" slot="reference">
        <div class="scanEr1">
          <div class="scanLogo">
            <img loading="lazy" src="@/assets/wechart.png"/>
          </div>
          <div class="fontEr">
            <span style="font-size: 14px;float:left;width: fit-content">关注公众号</span>
            <br>
            <span style="font-size: 12px;color: rgba(51,51,51,0.6);float: right;margin-top: 1.5vh">掌握最新职位动态</span>
          </div>
        </div>
      </el-button>
    </el-popover>

  </div>

</template>


<script>

export default {

  name: "",

  props: [],

  components: {},

  data() {

    return {
      content:'<img loading="lazy" src=\'@/assets/scan.png\' alt=\'描述文字\' style=\'width: 100px; height: auto; display: block; margin-top: 10px;\' />'
    }

  },

  methods: {},

  mounted() {


  }

}

</script>


<style type="text/scss" lang="scss" scoped>
.scanEr1{
  width: fit-content;
  height: fit-content;
  display: flex;
  //background: #FEFFFF;
  //
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  .fontEr{
    width:fit-content;
    margin:10px 0.2vw 10px 0.5vw;
  }
  .fontEr:hover{
  color:#111111;
  }
  .scanLogo{
    width:45px;
    height:45px;
    //padding: 10px;
    border-radius: 50%;
    background-color: #f4f5f6;
    box-shadow: -2px 1px 0px 1px #c4d3f8;
    margin:3px 0.3vw auto 0.8vw
  }
}

</style>
