import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import About from '../views/About.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    name: 'Home',
    meta: {
        nameEn: '首页',
    },
    component: Home
},{
        path: '/positionDetail',
        name: 'positionDetail',
        component: () => import( /* webpackChunkName: "about" */ '@/views/candidate/positionDetail.vue')
    },
    {
        path: '/bottomMobile',
        name: 'bottomMobile',
        meta: {
            nameEn: '移动底部栏',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/components/bottomMobile.vue')
    },
    {
    path: '/recruit',
    name: 'Recruit',
    meta: {
        nameEn: '我要求职',
    },
    component: () => import( /* webpackChunkName: "about" */ '@/views/candidate/recruit.vue')
},

    {
        path: '/AIinterview',
        name: 'AIinterview',
        meta: {
            nameEn: 'AI面试',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/PrimaryClassfy/AIinterview.vue')
    },


    {
        path: '/about',
        meta: {
            nameEn: '关于我们',
        },
        name: 'About',
        component: () => import( /* webpackChunkName: "about" */ '@/views/About.vue')
    },
    {
        path: '/Feedback',
        name: 'Feedback',
        meta: {
            nameEn: '意见反馈',
        },

        component: () => import( /* webpackChunkName: "about" */ '@/components/Feedback.vue')
    },
    {
        path: '/Comment',
        name: 'Comment',
        meta: {
            nameEn: '评价',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/FooterPage/Comment.vue')
    }, {
        path: '/help',
        name: 'help',
        meta: {
            nameEn: '帮助',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/FooterPage/help.vue')
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            nameEn: "登录"
        },
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/Register',
        name: 'Register',
        meta: {
            nameEn: "注册"
        },
        component: () => import('@/views/Register.vue')
    },
    {
        path: '/forgetPassword',
        name: 'forgetPassword',
        meta: {
            nameEn: "忘记密码"
        },
        component: () => import('@/views/forgetPassword.vue')
    },
    {
        path: '/basicInformation',
        name: 'BasicInformation',
        component: () => import('@/views/BasicInformation.vue'), //基本信息
        meta: {
            requireAuth: true,
            nameEn: '基本信息'
        },
    },

    {
        path: '/message',
        name: 'Message',
        component: () => import('@/views/Message.vue'),
        meta: {
            requireAuth: true,
            nameEn: '信息'
        },
        children: [{
            path: '/message',
            name: 'SystemMessages',
            component: () => import('@/views/SystemMessages.vue'), // 系统消息
            meta: {
                requireAuth: true,
                nameEn: '系统消息'
            },
        }, {
            path: '/subscriptionTheme',
            name: 'SubscriptionTheme',
            component: () => import('@/views/SubscriptionTheme.vue'), // 订阅主题
            meta: {
                requireAuth: true,
                nameEn: ' 订阅主题'
            },
        }, {
            path: '/receivedlikes',
            name: 'Receivedlikes',
            component: () => import('@/views/Receivedlikes.vue'), // 收到的赞
            meta: {
                requireAuth: true,
                nameEn: '回复我的'
            },
        }, {
            path: '/replyToMy',
            name: 'ReplyToMy',
            component: () => import('@/views/ReplyToMy.vue'), // 回复我的
            meta: {
                requireAuth: true,
                nameEn: '回复我的'
            },
        },]
    },
    {
        path: '/messageDetail',
        name: 'MessageDetail',
        component: () => import('@/views/MessageDetail.vue'), //系统消息详情
        meta: {
            requireAuth: true,
            nameEn: '系统消息详情',
        },
    },
    {
        path: '/mySubscriptions',
        name: 'MySubscriptions',
        meta: {
            nameEn: '我的订阅',
        },

        component: () => import('@/views/MySubscriptions.vue'), // 我的订阅
    },
    {
        path: '/Info1',
        name: 'Info1',
        component: () => import('@/views/userlog/Info1.vue'), // 个人信息
        meta: {
            requireAuth: true,
            needRefresh: true, // 需要刷新
            nameEn: ' 个人信息',
        },
    },
    {
        path: '/AIresume1',
        name: 'AIresume1',
        component: () => import('@/views/userlog/AIresume1.vue'), // 个人信息
        meta: {
            requireAuth: true,
            needRefresh: true, // 需要刷新
            nameEn: ' GPT面试',
        },
    },
    {
        path: '/jobCommit1',
        name: 'jobCommit1',
        component: () => import('@/views/userlog/jobCommit1.vue'), // 个人信息
        meta: {
            requireAuth: true,
            needRefresh: true, // 需要刷新
            nameEn: '我的投递',
        },
    },
    {
        path: '/secure1',
        name: 'secure1',
        component: () => import('@/views/userlog/secure1.vue'), // 个人信息
        meta: {
            requireAuth: true,
            needRefresh: true, // 需要刷新
            nameEn: ' 个人信息',
        },
    },   {
        path: '/changePhone1',
        name: 'changePhone1',
        component: () => import('@/views/userlog/ChangePhone1.vue'), // 个人信息
        meta: {
            requireAuth: true,
            needRefresh: true, // 需要刷新
            nameEn: ' 个人信息',
        },
    },   {
        path: '/changeEmail1',
        name: 'changeEmail1',
        component: () => import('@/views/userlog/ChangeEmail1.vue'), // 个人信息
        meta: {
            requireAuth: true,
            needRefresh: true, // 需要刷新
            nameEn: ' 个人信息',
        },
    },   {
        path: '/jobCollect1',
        name: 'jobCollect1',
        component: () => import('@/views/userlog/jobCollect1.vue'), // 个人信息
        meta: {
            requireAuth: true,
            needRefresh: true, // 需要刷新
            nameEn: ' 个人信息',
        },
    },   {
        path: '/jobCommunicate1',
        name: 'jobCommunicate1',
        component: () => import('@/views/userlog/jobCommunicate1.vue'), // 个人信息
        meta: {
            requireAuth: true,
            needRefresh: true, // 需要刷新
            nameEn: ' 个人信息',
        },
    },  {
        path: '/changePassword1',
        name: 'changePassword1',
        component: () => import('@/views/userlog/ChangePassword1.vue'), // 个人信息
        meta: {
            requireAuth: true,
            needRefresh: true, // 需要刷新
            nameEn: ' 个人信息',
        },
    },  {
        path: '/secure1',
        name: 'secure1',
        component: () => import('@/views/userlog/secure1.vue'), // 个人信息
        meta: {
            requireAuth: true,
            needRefresh: true, // 需要刷新
            nameEn: ' 个人信息',
        },
    },
    {
        path: '/user',
        name: 'User',
        component: () => import('@/views/User.vue'),
        meta: {
            requireAuth: true,
            nameEn:' 个人信息',
        },
        children: [
            {
                path: '/jobCommunicate',
                name: 'jobCommunicate',
                meta: {
                    nameEn: '职位沟通',
                },
                component: () => import( /* webpackChunkName: "about" */ '@/views/candidate/jobCommunicate.vue')
            },{
            path: '/Info',
            name: 'Info',
            component: () => import('@/views/Info.vue'), // 个人信息
            meta: {
                requireAuth: true,
                needRefresh: true ,// 需要刷新
                nameEn:' 个人信息',
            },
        },
            {
                path: '/AIresume',
                name: 'AIresume',
                meta: {
                    nameEn: 'AI简历',
                },
                component: () => import( /* webpackChunkName: "about" */ '@/views/candidate/AIresume.vue')

            },

            {
            path: '/jobCollect',
            name: 'jobCollect',
            component: () => import('@/views/candidate/jobCollect.vue'), // 个人信息
            meta: {
                requireAuth: true,
                needRefresh: true ,// 需要刷新
                nameEn:'职位收藏',
            },
        },
            {
                path: '/jobCommit',
                name: 'jobCommit',
                component: () => import('@/views/candidate/jobCommit.vue'), // 个人信息
                meta: {
                    requireAuth: true,
                    needRefresh: true ,// 需要刷新
                    nameEn:'职位收藏',
                },
            },
            {
                path: '/secure1',
                name: 'Secure1',
                component: () => import('@/views/userlog/secure1.vue'), // 账户安全
                meta: {
                    requireAuth: true,
                    needRefresh: true ,// 需要刷新
                    nameEn:'账户安全',
                },
            },

            {
            path: '/secure',
            name: 'Secure',
            component: () => import('@/views/Secure.vue'), // 账户安全
            meta: {
                requireAuth: true,
                needRefresh: true ,// 需要刷新
                nameEn:'账户安全',
            },
        }, {
            path: '/changePhone',
            name: 'ChangePhone',
            component: () => import('@/views/ChangePhone.vue'), // 修改手机号
            meta: {
                requireAuth: true,
                nameEn:'修改手机号',
            },
        }, {
            path: '/changePassword',
            name: 'ChangePassword',
            component: () => import('@/views/ChangePassword.vue'), // 修改密码
            meta: {
                requireAuth: true,
                nameEn:'修改密码',
            },
        }, {
            path: '/changeEmail',
            name: 'ChangeEmail',
            component: () => import('@/views/ChangeEmail.vue'), // 修改密码
            meta: {
                requireAuth: true,
                nameEn:'修改密码',
            },
        }, {
            path: '/information',
            name: 'Information',
            component: () => import('@/views/Information.vue'), // 资讯管理
            meta: {
                requireAuth: true,
                nameEn:' 资讯管理'
            },
        }, {
            path: '/topicSubscription',
            name: 'TopicSubscription',
            meta: {
                nameEn: '主题订阅',
                requireAuth: true,
            },
            component: () => import('@/views/TopicSubscription.vue'), // 主题订阅

        },]
    },
    {
        path: '/wechatCallback',
        name: 'WechatCallback',
        meta: {
            nameEn: '微信绑定'
        },
        component: () => import('@/views/WechatCallback.vue'), // 微信绑定账号
    },
    {
        path: '/success',
        name: 'Success',
        meta: {
            nameEn: '微信绑定成功'
        },
        component: () => import('@/views/Success.vue'), // 微信绑定提示
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
// router.beforeEach((to, from, next) => {
//
//     console.log(from)
//     console.log(next)
//     if (to.path === '/recruitneed') {
//         window.onload = function() {
//             window.scrollTo(0, 0);
//         }
//     }
//      document.title = "油气人才-" + to.meta.nameEn;
//
// });


export default router
