import { render, staticRenderFns } from "./Erweima.vue?vue&type=template&id=8a32cf94&scoped=true"
import script from "./Erweima.vue?vue&type=script&lang=js"
export * from "./Erweima.vue?vue&type=script&lang=js"
import style0 from "./Erweima.vue?vue&type=style&index=0&id=8a32cf94&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a32cf94",
  null
  
)

export default component.exports